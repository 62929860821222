// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["NlDq2rcMj", "wFNwZRMkn", "sB3B3BgFy"];

const variantClassNames = {NlDq2rcMj: "framer-v-tgv2oz", sB3B3BgFy: "framer-v-n9va60", wFNwZRMkn: "framer-v-pve9so"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "NlDq2rcMj", "Variant 2": "wFNwZRMkn", "Variant 3": "sB3B3BgFy"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, rz_MBPOVE: title ?? props.rz_MBPOVE ?? "www.ppauto.sk", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "NlDq2rcMj"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, rz_MBPOVE, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "NlDq2rcMj", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1ewftvm = activeVariantCallback(async (...args) => {
setVariant("wFNwZRMkn")
})

const onMouseLeaveth6s7j = activeVariantCallback(async (...args) => {
setVariant("sB3B3BgFy")
})

const isDisplayed = () => {
if (["wFNwZRMkn", "sB3B3BgFy"].includes(baseVariant)) return false;
return true
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-j2pot", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-tgv2oz", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"NlDq2rcMj"} onMouseEnter={onMouseEnter1ewftvm} ref={ref} style={{...style}} {...addPropertyOverrides({sB3B3BgFy: {"data-framer-name": "Variant 3"}, wFNwZRMkn: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeaveth6s7j}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItcmVndWxhcg==", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-line-height": "37px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(242, 242, 242))"}}><Link href={"https://framer.com/projects/Untitled--dAysqk0NWzjmeo2FFBmZ-giY4Z?node=NU0vuFcN7"} openInNewTab smoothScroll={false}><motion.a><motion.span style={{"--framer-font-size": "25.3332px"}}>www.ppauto.sk</motion.span></motion.a></Link><motion.span style={{"--framer-font-size": "25.3332px"}}><motion.br/></motion.span></motion.p></React.Fragment>} className={"framer-zx8fvo"} fonts={["GF;Inter-regular"]} layoutDependency={layoutDependency} layoutId={"fzx1vb6LB"} style={{"--extracted-r6o4lv": "rgb(242, 242, 242)", "--framer-paragraph-spacing": "0px"}} text={rz_MBPOVE} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-j2pot [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-j2pot .framer-1k206m8 { display: block; }", ".framer-j2pot .framer-tgv2oz { height: 40px; overflow: hidden; position: relative; width: 203px; }", ".framer-j2pot .framer-zx8fvo { -webkit-user-select: none; flex: none; height: auto; left: 0px; pointer-events: auto; position: absolute; right: 0px; top: 50%; user-select: none; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 203
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wFNwZRMkn":{"layout":["fixed","fixed"]},"sB3B3BgFy":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"rz_MBPOVE":"title"}
 * @framerImmutableVariables false
 */
const FramerjFC9WWqiq: React.ComponentType<Props> = withCSS(Component, css, "framer-j2pot") as typeof Component;
export default FramerjFC9WWqiq;

FramerjFC9WWqiq.displayName = "ppauto link c";

FramerjFC9WWqiq.defaultProps = {height: 40, width: 203};

addPropertyControls(FramerjFC9WWqiq, {variant: {options: ["NlDq2rcMj", "wFNwZRMkn", "sB3B3BgFy"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, rz_MBPOVE: {defaultValue: "www.ppauto.sk", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerjFC9WWqiq, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/jFC9WWqiq:default", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf", weight: "400"}])